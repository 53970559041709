import React, { useState } from "react";
import classes from "./Help.module.css";
import { setDoc, doc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { v4 as uuidv4 } from "uuid";
import Alert from "react-bootstrap/Alert";
const Help = () => {
    const [name, setName] = useState("");
    const [employeeType, setEmployeeType] = useState("student");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [msg, setMsg] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [varient, setVarient] = useState("");

    const submitForm = async (event) => {
        event.preventDefault();
        console.log(name, email, employeeType, phoneNo, msg);
        try {
            const docRef = doc(collection(db, "queries"), uuidv4());
            await setDoc(docRef, {
                Name: name,
                email,
                Mobile: phoneNo,
                EmployeeType: employeeType,
                Message: msg,
            });
            console.log("msg sent");
            setEmail("");
            setName("");
            setEmployeeType("student");
            setMsg("");
            setPhoneNo("");
            setShowAlert(true);
            setErrorMessage("Message sent successfully");
            setVarient("success");
        } catch (err) {
            setShowAlert(true);
            setErrorMessage("Some error occured !!! Please try again");
            setVarient("danger");
        }
    };
    return (
        <div className="mb-4 text-center">
            <h2 className="h1-responsive font-weight-bold text-center my-4">
                Get in Touch
            </h2>
            <p className="text-center w-responsive mx-auto mb-5">
                Do you have any questions? Please do not hesitate to contact us
                directly. Our team will come back to you within a matter of
                hours to help you.
            </p>

            <div className={`container mx-auto px-4 ${classes.container}`}>
                {showAlert ? (
                    <Alert
                        variant={varient}
                        onClose={() => setShowAlert(false)}
                        dismissible
                    >
                        <p>{errorMessage}</p>
                    </Alert>
                ) : (
                    <div></div>
                )}
                <div className="form-control">
                    <form onSubmit={submitForm}>
                        <input
                            type="hidden"
                            name="_subject"
                            value="Contact request from IDF website"
                        />
                        <div className="col-md-12 p-2 text-center">
                            <div className="md-form mb-0">
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                    className="form-control"
                                    placeholder="Name"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-12 p-2 text-center">
                            <div className="md-form mb-0">
                                <select
                                    required
                                    value={employeeType}
                                    onChange={(event) => {
                                        setEmployeeType(event.target.value);
                                    }}
                                    name="employeeType"
                                    className="form-control"
                                    id="employeeType"
                                >
                                    <option value="student">Student</option>
                                    <option value="employee">Employee</option>
                                    <option value="self">self-employed</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12 p-2 text-center">
                            <div className="md-form mb-0">
                                <input
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                    type="email"
                                    name="_replyto"
                                    className="form-control"
                                    placeholder="Your email ID"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-12 p-2 text-center">
                            <div className="md-form mb-0">
                                <input
                                    value={phoneNo}
                                    onChange={(event) => {
                                        setPhoneNo(event.target.value);
                                    }}
                                    type="text"
                                    name="_replyto"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-12 p-2 text-center">
                            <div className="md-form mb-0">
                                <textarea
                                    value={msg}
                                    onChange={(event) => {
                                        setMsg(event.target.value);
                                    }}
                                    name="message"
                                    rows={5}
                                    placeholder="Your message"
                                    className="form-control"
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <br />
                        <div className="text-center text-md-left">
                            <button type="submit" className="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Help;
