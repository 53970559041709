import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import sid from '../assets/Dev/siddharth_pandey.png';
import rishi from '../assets/Dev/kaushik_rishi.png';
import dhiraj from '../assets/Dev/dhiraj_kumar.png';
import mahaboob from '../assets/Dev/mahaboob_shaik.png';
import sashidhar from '../assets/Dev/sashidhar_motte.png';
import yash from '../assets/Dev/yash.jpeg';
import Diwan from '../assets/core/Diwan.jpg';
import Abhinav from '../assets/core/Abhinav.jpg';
import Prabhjot from '../assets/core/Prabhjot.jpg';
import Kasiviswanathan from '../assets/core/Kasiviswanathan.jpeg';
import { Link } from "react-router-dom";
import { BsLinkedin, BsGithub } from "react-icons/bs";


const Team = () => {

    const devTeamData = [
        {
            name: "Kaushik Rishi",
            title: "Developer",
            subtitle: "GDSC Web Dev Lead",
            linkedIn: 'https://www.linkedin.com/in/kaushikrishi',
            github: 'https://www.github.com/kaushik-rishi',
            image: rishi
        },
        {
            name: 'Siddharth Pandey',
            title: "Developer",
            subtitle: "GDSC IIITS Lead",
            linkedIn: 'https://www.linkedin.com/in/siddharth25pandey/',
            github: 'https://github.com/siddharth25pandey',
            image: sid
        },
        {
            name: "Dhiraj Kumar Ch",
            title: 'Developer',
            subtitle: "GDSC Design & Management Lead",
            linkedIn: 'https://www.linkedin.com/in/dhiraj-kumar-chintada-5639b0191',
            github: '#',
            image: dhiraj
        },
        {
            name: 'Sashidhar Motte',
            title: "Developer",
            subtitle: "GDSC Design & Management Lead",
            linkedIn: 'https://www.linkedin.com/in/sashidhar-motte-155726194',
            github: 'https://github.com/Sashi445',
            image: sashidhar
        },
        {
            name: 'Mahaboob Shaik',
            title: "Developer",
            subtitle: "GDSC App Dev Lead",
            linkedIn: 'https://www.linkedin.com/in/mahaboobshaik-developer',
            github: "https://www.github.com/bannu0snake",
            image: mahaboob
        },
        {
            name: 'Yash Shukla',
            title: "Developer",
            subtitle: "GDSC Core Member",
            linkedIn: 'https://www.linkedin.com/in/master-yash/',
            github: "https://github.com/Yash1256",
            image: yash
        }
    ];

    const coreTeamData = [
        {
            name: "Prof. K. S. Kasiviswanathan",
            title: "Principal Investigator ",
            subtitle: "Dept. of WRD&M, IIT Roorkee",
            image:  Kasiviswanathan
        },
        {
            name: "Abhinav Wadhwa",
            title: "Post Doctoral Fellow",
           
            image: Abhinav
        },
        {
            name: "Diwan Mohaideen",
            title: "Post Doctoral Fellow",
          
            image: Diwan
        },
        {
            name: "Prabhjot Singh",
            title: "M.Tech Student",
            
            image:  Prabhjot
        }
    ];

    return (
        <div className="container py-5">

            <div className="container mb-5">
                <div className='py-5 text-center' >
                    <h2 className="logoFont">Core Team</h2>
                </div>
                <div className="row justify-content-center align-items-stretch">
                    {coreTeamData.map((i,indx) => (<div className='m-2 col-lg-2 col-md-6 card shadow-6 py-2' key={indx}>
                        <div className="text-center">
                            <img src={i.image} alt="" className="rounded" style={{ width: "200px",
            height: "200px"}}/>
                            <div className="py-3">
                                <h4>
                                    {i.name}
                                </h4>
                                <p className="fs-6" style={{ color: 'red' }} >{i.title}</p>
                                
                                <p className="fs-6">{i.subtitle}</p>
                                   
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>

            <hr />

            <div className="container mb-10">
                <div className='py-5 text-center' >
                    <h2 className="logoFont">Development Team</h2>
                </div>
                <div className="row justify-content-center align-items-stretch">
                    {devTeamData.map((i,indx) => (<div className='m-2 col-lg-2 col-md-6 card shadow-6 py-2' key={indx}>
                        <div className="text-center">
                            <img src={i.image} alt="" className="rounded" />
                            <div className="py-3">
                                <h4>
                                    {i.name}
                                </h4>
                                <p className="fs-6">{i.title}</p>
                                <p className="fs-6" style={{ color: 'blue' }}>{i.subtitle}</p>
                                <div className="my-2 d-flex align-items-center justify-content-center">
                                    <div>
                                        <a className='me-2' style={{ color: 'black' }} href={i.linkedIn}><BsLinkedin color='#0e76a8' size={25} /></a>
                                        <a className='me-2' style={{ color: 'black' }} href={i.github}><BsGithub size={25} /></a>
                                    </div>
                                    {/* <span className="mx-2">
                                        <a href={i.linkedIn}>LinkedIn</a>
                                    </span>
                                    <span className="me-2">
                                        <a href={i.github}>Github</a>
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>

            </div>


            {/* <div style={{
                color: "blue",
                textAlign: "center",
                fontSize: "40px",
                padding: "8px"
            }}>Development Team</div>
            <br />
            <CardGroup>
                <Card>
                    <Card.Img variant="top" src={rishi} style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>Kaushik Rishi</Card.Title>
                        <Card.Link href="https://www.linkedin.com/in/kaushikrishi">Linkedin</Card.Link>
                        <Card.Link href="https://www.github.com/kaushik-rishi">Github</Card.Link>
                    </Card.Body>

                </Card>
                <Card>
                    <Card.Img variant="top" src={sid} style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>Siddahrth Pandey</Card.Title>

                        <Card.Link href="https://www.linkedin.com/in/siddharth25pandey/">Linkedin</Card.Link>
                        <Card.Link href="https://github.com/siddharth25pandey">Github</Card.Link>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={dhiraj} style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>Dhiraj Kumar Ch</Card.Title>

                        <Card.Link href="https://www.linkedin.com/in/dhiraj-kumar-chintada-5639b0191">Linkedin</Card.Link>
                        <Card.Link href="#">Github</Card.Link>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={sashidhar} style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>Sashidhar Motte</Card.Title>

                        <Card.Link href="https://www.linkedin.com/in/sashidhar-motte-155726194">Linkedin</Card.Link>
                        <Card.Link href="https://www.github.com/Sashi445">Github</Card.Link>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={mahaboob} style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>Mahaboob Shaik</Card.Title>

                        <Card.Link href="https://www.linkedin.com/in/mahaboobshaik-developer">Linkedin</Card.Link>
                        <Card.Link href="https://www.github.com/bannu0snake">Github</Card.Link>
                    </Card.Body>
                </Card>
            </CardGroup> */}

            {/* <div style={{
                color: "blue",
                textAlign: "center",
                fontSize: "40px",
                padding: "8px"
            }}>Core Team</div>
            <br />
            <CardGroup>

                <Card>
                    <Card.Img variant="top" src="" style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>Yash Shukla</Card.Title>

                        <Card.Link href="#">Linkedin</Card.Link>
                        <Card.Link href="#">Github</Card.Link>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="" style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>ABCD</Card.Title>

                        <Card.Link href="#">Linkedin</Card.Link>
                        <Card.Link href="#">Github</Card.Link>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="" style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>ABCD</Card.Title>

                        <Card.Link href="#">Linkedin</Card.Link>
                        <Card.Link href="#">Github</Card.Link>
                    </Card.Body>
                </Card>


                <Card>
                    <Card.Img variant="top" src="" style={{
                        width: "150px",
                        height: "150px"
                    }} />
                    <Card.Body>
                        <Card.Title>ABCD</Card.Title>

                        <Card.Link href="#">Linkedin</Card.Link>
                        <Card.Link href="#">Github</Card.Link>
                    </Card.Body>
                </Card>
            </CardGroup> */}
        </div>
    );
};

export default Team;
