import React from 'react';
import TitleComponent from './TitleComponent';


const Footer = () => {
  return (
    <div className="bg-secondary py-5 position-static bottom-0">
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-4 col">
            <h1 className='logoFont mb-1'><TitleComponent/></h1>
          </div>
          {/*<div className="col-lg-4 col">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <h4>Research Team</h4>
                <ul id="reseachteam" style={{ listStyle: "none" }}>
                  <li>Dr.Kasi Vishwanathan</li>
                  <li>Name 2</li>
                  <li>Name 3</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <h4 className=''>Important Links</h4>
                <ul id="reseachteam" style={{ listStyle: "none" }}>
                  <li className="link">link1</li>
                  <li className="link">link2</li>
                  <li className="link">link3</li>
                </ul>
              </div>
            </div>
          </div>
  </div>*/}
          {/* <div>
          <div className='d-flex align-items-start justify-content-center'>
            <div className='' style={{ height: '80px' }}>
              <img style={{
                maxWidth: '100%',
                height: 'auto',
                width: 'auto',
                maxHeight: '100%'
              }} src={logo2} alt="" />
            </div>
            <div className='px-2' style={{}}>
              <h5>Indian Institute of Technology, Roorkee</h5>
            </div>
            </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Footer;
