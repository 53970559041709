import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase-config";
import { signOut } from "firebase/auth";
import TitleComponent from './../components/TitleComponent';

const Navbar = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const logOut = async () => {
    await signOut(auth);
    navigate("/info");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark px-0 py-3 sticky-top" style={{
      backgroundColor : '#022d45'
    }}>
      <div className="container-xl">
        <Link className="navbar-brand" to="/">
          <h2 className="text-white logoFont"><TitleComponent/></h2>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-lg-auto">
            {/* {
                            user?(
                                <Link className="nav-item nav-link active" to="/" >Home</Link>
                            ):(
                                <div />
                            )
                        } */}
            <Link className="nav-item nav-link active" to="/">
              Home
            </Link>
            <Link className="nav-item nav-link" to="/team">
              Team
            </Link>
            <Link className="nav-item nav-link" to="/publications">
              Publications
            </Link>
            <Link className="nav-item nav-link" to="/outreach">
              Outreach
            </Link>
            <Link className="nav-item nav-link" to="/help">
              Help
            </Link>
          </div>
          {user ? (
            <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
              <div
                className="btn btn-sm btn-primary w-full w-lg-auto"
                onClick={logOut}
              >
                Logout
              </div>
            </div>
          ) : (
            <div className="navbar-nav ms-lg-4">
              <div className="navbar-nav ms-lg-4">
                <Link className="nav-item nav-link" to="/login">
                  Login
                </Link>
              </div>
              <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
                <Link
                  to="/register"
                  className="btn btn-sm btn-primary w-full w-lg-auto"
                >
                  Register
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
