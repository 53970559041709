const Disclaimer = () => {
    return <div className="bg-surface-secondary">
        <div className="container py-3">
            <p className="text-center">
                <strong>Disclaimer:</strong> This tool is developed using openly available interpolated and satellite data. More validation is required for checking the accuracy, dependency using locally available information.
            </p>
        </div>
    </div>
}

export default Disclaimer;