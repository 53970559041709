import React from 'react';


const ModelDescription = () => {
  return (
    <div>
        ModelDescription Details
    </div>
  );
};

export default ModelDescription;
