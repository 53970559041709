import React, { useState } from "react";
import AuthBase from "./AuthBase";
import { Link, useNavigate } from "react-router-dom";
import {setDoc, doc } from "firebase/firestore";
import { auth,db } from "../firebase-config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Alert from "react-bootstrap/Alert";
const RegisterPage = () => {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [affliation, setAffiliation] = useState("");
    const [occupation, setOccupation] = useState("");
    const [intentOfUse, setIntentOfUse] = useState("");
    const [name, setName] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const register = async (e) => {
        e.preventDefault();
        try {
            if (registerPassword !== confirmPassword) {
                throw new Error("both passwords should match");
            }
            const data = await createUserWithEmailAndPassword(
                auth,
                registerEmail,
                registerPassword
            );
            console.log(data.user.uid);
            console.log(
                name,
                registerEmail,
                occupation,
                affliation,
                intentOfUse
            );
            await setDoc(doc(db, "users",data.user.uid), {name, registerEmail, occupation, affliation, intentOfUse });

            setErrorMessage("You have successfully registered");
            setShowAlert(true);
            navigate("/info");
        } catch (error) {
            if (error === "both passwords should match") {
                setRegisterPassword("");
                setConfirmPassword("");
                setErrorMessage("Passwords doesn't match");
            } else if (error.code === "auth/invalid-email") {
                setRegisterEmail("");
                setErrorMessage("Invalid Email");
            } else if (error.code === "auth/email-already-in-use") {
                setRegisterEmail("");
                setErrorMessage("Username already in use, please login");
            } else if (error.code === "auth/weak-password") {
                setRegisterPassword("");
                setConfirmPassword("");
                setErrorMessage(
                    "Password should be more than 6 characters long"
                );
            }
            setShowAlert(true);
        }
    };

    return (
        <AuthBase>
            <>
                <div>
                    <div className="mb-5">
                        <label className="form-label" htmlFor="name">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control form-control-muted"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-5 ">
                        <label className="form-label" htmlFor="email">
                            Email address
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control form-control-muted"
                            value={registerEmail}
                            onChange={(e) => setRegisterEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label" htmlFor="password1">
                            Create Password
                        </label>
                        <input
                            type="password"
                            name="password1"
                            id="password1"
                            className="form-control form-control-muted"
                            value={registerPassword}
                            onChange={(e) =>
                                setRegisterPassword(e.target.value)
                            }
                            required
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label" htmlFor="password2">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            name="password2"
                            id="password2"
                            className="form-control form-control-muted"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label" htmlFor="Affiliation">
                            Affiliation
                        </label>
                        <input
                            type="text"
                            name="Affiliation"
                            id="Affiliation"
                            className="form-control form-control-muted"
                            value={affliation}
                            onChange={(e) => setAffiliation(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label" htmlFor="occupation">
                            Organization
                        </label>
                        <input
                            type="text"
                            name="occupation"
                            id="occupation"
                            className="form-control form-control-muted"
                            value={occupation}
                            onChange={(e) => setOccupation(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label" htmlFor="intentOfUse">
                            Intent Of Use
                        </label>
                        <input
                            type="text"
                            name="intentOfUse"
                            id="intentOfUse"
                            className="form-control form-control-muted"
                            value={intentOfUse}
                            onChange={(e) => setIntentOfUse(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn btn-primary w-full"
                            onClick={register}
                        >
                            Register
                        </button>
                    </div>
                </div>
                <div className="my-6">
                    <small>Already have an account ?</small>
                    <Link
                        to="/login"
                        className="text-warning text-sm font-semibold"
                    >
                        Login
                    </Link>
                </div>
                {showAlert ? (
                    <Alert
                        variant="danger"
                        onClose={() => setShowAlert(false)}
                        dismissible
                    >
                        <Alert.Heading>
                            Oh snap! You got an error!
                        </Alert.Heading>
                        <p>{errorMessage}</p>
                    </Alert>
                ) : (
                    <div></div>
                )}
            </>
        </AuthBase>
    );
};

export default RegisterPage;
