import React from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import TitleComponent from "./../components/TitleComponent";

const LandingPage = () => {
  const [user, loading] = useAuthState(auth);
  return (
    <div className="container py-8 mb-10">
      <div className="text-center">
        <h2 className="mb-2 logoFont">
          <TitleComponent />
        </h2>
        <p>
          A Web-based Tool for obtaining the design rainfall from the
          Intensity-Duration-Frequency Curves over the Indian landmass Web-based
          IDF curves is a publically available intensity-duration-frequency tool
          to get the design rainfall of different return periods under the base
          and future climate periods. The Web-based IDF contains the developed
          IDF curves for the grid size of 25 km by 25 km covering the entire
          India. Users can select any grid for the region of interest to know
          design rainfall having a minimum of 5- year and maximum of 100 - year
          return period. By accessing any of the options available in this tool
          you agree with the Terms of Use
        </p>
      </div>
      <hr className="bg-secondary" />
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-6 col">
          <div className="card shadow-5">
            <div className="p-2">
              {" "}
              <img
                className="card-img"
                src="https://www.thestatesman.com/wp-content/uploads/2020/04/googl_ED.jpg"
                alt=""
              />
            </div>
            <div className="card-body text-center">
              <h3 className="mb-3">IDF's for historical data</h3>
              {!user ? (
                <Link to="/login" className="btn btn-dark">
                  Checkout
                </Link>
              ) : (
                <Link to="/history" className="btn btn-dark">
                  Checkout
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col">
          <div className="card shadow-5">
            <div className="p-2">
              {" "}
              <img
                className="card-img"
                src="https://www.thestatesman.com/wp-content/uploads/2020/04/googl_ED.jpg"
                alt=""
              />
            </div>
            <div className="card-body text-center">
              <h3 className="mb-3">IDF's for Future climate period</h3>
              {!user ? (
                <Link to="/login" className="btn btn-dark" disabled="True">
                  Checkout
                </Link>
              ) : (
                <Link to="/future" className="btn btn-dark">
                  Checkout
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
