import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";

function Tables({ gridData, coordinates, rcp }) {
  const { timeline } = useParams();
  return (
    <Table striped bordered hover size="md" responsive>
      <tbody>
        <tr>
          <td rowSpan={2}>Return Period</td>

          <td colSpan={6}>Design Rainfall</td>
        </tr>
        <tr>
          <td>Sub Hourly</td>
          <td>Hourly</td>
          <td>Three Hourly</td>
          <td>Six Hourly</td>
          <td>Twelve Hourly</td>
          <td>Twenty four Hourly</td>
        </tr>
        {timeline === "future" ? (
          rcp === "rcp4" ? (
            <>
              <tr>
                <td>5</td>
                <td>
                  {Math.trunc(gridData.rcp45.sub_hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.sub_hourly.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly.stddev.qq5)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp45.three_hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.three_hourly.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.six_hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.six_hourly.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_12.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_12.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_24.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_24.stddev.qq5)}
                </td>
              </tr>
              <tr>
                <td>25</td>
                <td>
                  {Math.trunc(gridData.rcp45.sub_hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.sub_hourly.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly.stddev.qq25)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp45.three_hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.three_hourly.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.six_hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.six_hourly.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_12.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_12.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_24.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_24.stddev.qq25)}
                </td>
              </tr>
              <tr>
                <td>100</td>
                <td>
                  {Math.trunc(gridData.rcp45.sub_hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.sub_hourly.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly.stddev.qq100)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp45.three_hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.three_hourly.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.six_hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.six_hourly.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_12.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_12.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_24.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_24.stddev.qq100)}
                </td>
              </tr>
              <tr>
                <td>500</td>
                <td>
                  {Math.trunc(gridData.rcp45.sub_hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.sub_hourly.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly.stddev.qq500)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp45.three_hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.three_hourly.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.six_hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.six_hourly.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_12.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_12.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp45.hourly_24.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp45.hourly_24.stddev.qq500)}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>5</td>
                <td>
                  {Math.trunc(gridData.rcp85.sub_hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.sub_hourly.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly.stddev.qq5)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp85.three_hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.three_hourly.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.six_hourly.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.six_hourly.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_12.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_12.stddev.qq5)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_24.mean.qq5)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_24.stddev.qq5)}
                </td>
              </tr>
              <tr>
                <td>25</td>
                <td>
                  {Math.trunc(gridData.rcp85.sub_hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.sub_hourly.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly.stddev.qq25)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp85.three_hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.three_hourly.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.six_hourly.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.six_hourly.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_12.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_12.stddev.qq25)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_24.mean.qq25)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_24.stddev.qq25)}
                </td>
              </tr>
              <tr>
                <td>100</td>
                <td>
                  {Math.trunc(gridData.rcp85.sub_hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.sub_hourly.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly.stddev.qq100)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp85.three_hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.three_hourly.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.six_hourly.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.six_hourly.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_12.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_12.stddev.qq100)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_24.mean.qq100)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_24.stddev.qq100)}
                </td>
              </tr>
              <tr>
                <td>500</td>
                <td>
                  {Math.trunc(gridData.rcp85.sub_hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.sub_hourly.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly.stddev.qq500)}
                </td>

                <td>
                  {Math.trunc(gridData.rcp85.three_hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.three_hourly.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.six_hourly.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.six_hourly.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_12.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_12.stddev.qq500)}
                </td>
                <td>
                  {Math.trunc(gridData.rcp85.hourly_24.mean.qq500)}
                  <span>&#177;</span>
                  {Math.trunc(gridData.rcp85.hourly_24.stddev.qq500)}
                </td>
              </tr>
            </>
          )
        ) : (
          <>
            <tr>
              <td>5</td>
              <td>
                {Math.trunc(gridData.sub_hourly.mean.qq5)}
                <span>&#177;</span>
                {Math.trunc(gridData.sub_hourly.stddev.qq5)}
              </td>
              <td>
                {Math.trunc(gridData.hourly.mean.qq5)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly.stddev.qq5)}
              </td>

              <td>
                {Math.trunc(gridData.three_hourly.mean.qq5)}
                <span>&#177;</span>
                {Math.trunc(gridData.three_hourly.stddev.qq5)}
              </td>
              <td>
                {Math.trunc(gridData.six_hourly.mean.qq5)}
                <span>&#177;</span>
                {Math.trunc(gridData.six_hourly.stddev.qq5)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_12.mean.qq5)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_12.stddev.qq5)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_24.mean.qq5)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_24.stddev.qq5)}
              </td>
            </tr>
            <tr>
              <td>25</td>
              <td>
                {Math.trunc(gridData.sub_hourly.mean.qq25)}
                <span>&#177;</span>
                {Math.trunc(gridData.sub_hourly.stddev.qq25)}
              </td>
              <td>
                {Math.trunc(gridData.hourly.mean.qq25)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly.stddev.qq25)}
              </td>

              <td>
                {Math.trunc(gridData.three_hourly.mean.qq25)}
                <span>&#177;</span>
                {Math.trunc(gridData.three_hourly.stddev.qq25)}
              </td>
              <td>
                {Math.trunc(gridData.six_hourly.mean.qq25)}
                <span>&#177;</span>
                {Math.trunc(gridData.six_hourly.stddev.qq25)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_12.mean.qq25)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_12.stddev.qq25)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_24.mean.qq25)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_24.stddev.qq25)}
              </td>
            </tr>
            <tr>
              <td>100</td>
              <td>
                {Math.trunc(gridData.sub_hourly.mean.qq100)}
                <span>&#177;</span>
                {Math.trunc(gridData.sub_hourly.stddev.qq100)}
              </td>
              <td>
                {Math.trunc(gridData.hourly.mean.qq100)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly.stddev.qq100)}
              </td>

              <td>
                {Math.trunc(gridData.three_hourly.mean.qq100)}
                <span>&#177;</span>
                {Math.trunc(gridData.three_hourly.stddev.qq100)}
              </td>
              <td>
                {Math.trunc(gridData.six_hourly.mean.qq100)}
                <span>&#177;</span>
                {Math.trunc(gridData.six_hourly.stddev.qq100)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_12.mean.qq100)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_12.stddev.qq100)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_24.mean.qq100)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_24.stddev.qq100)}
              </td>
            </tr>
            <tr>
              <td>500</td>
              <td>
                {Math.trunc(gridData.sub_hourly.mean.qq500)}
                <span>&#177;</span>
                {Math.trunc(gridData.sub_hourly.stddev.qq500)}
              </td>
              <td>
                {Math.trunc(gridData.hourly.mean.qq500)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly.stddev.qq500)}
              </td>

              <td>
                {Math.trunc(gridData.three_hourly.mean.qq500)}
                <span>&#177;</span>
                {Math.trunc(gridData.three_hourly.stddev.qq500)}
              </td>
              <td>
                {Math.trunc(gridData.six_hourly.mean.qq500)}
                <span>&#177;</span>
                {Math.trunc(gridData.six_hourly.stddev.qq500)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_12.mean.qq500)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_12.stddev.qq500)}
              </td>
              <td>
                {Math.trunc(gridData.hourly_24.mean.qq500)}
                <span>&#177;</span>
                {Math.trunc(gridData.hourly_24.stddev.qq500)}
              </td>
            </tr>
          </>
        )}
        <tr></tr>
      </tbody>
    </Table>
  );
}

export default Tables;
