import React from 'react';


const DataPage = () => {
  return (
    <div>
        DataPage Details
    </div>
  );
};

export default DataPage;
