import { Modal } from "react-bootstrap";
import GraphComponent from "./GraphComponent";
import gridLatLong from "../lib/grid_lat_long.json";
import gridData from "../lib/Future/future_data.json";
import pastGridData from "../lib/Past/historic_data.json";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Tables from "./Tables";
import { useParams } from "react-router-dom";
import { useState } from "react";

const CellModal = ({ handleClose, bounds, ...rest }) => {
  const onHide = handleClose;
  const { timeline } = useParams();
  const [key, setKey] = useState("rcp4");
  const [hourKey, setHourKey] = useState("sub_hourly");

  return (
    <Modal
      {...rest}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">IDF Curve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="plots"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="plots" title="Plots">
            {timeline === "future" ? (
              <Tabs
                id="uncontrolled-tab-example"
                defaultActiveKey="rcp4"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="rcp4" title="RCP 4.5">
                  <Tabs
                    defaultActiveKey="sub_hourly"
                    id="uncontrolled"
                    className="mb-3"
                    activeKey={hourKey}
                    onSelect={(k) => setHourKey(k)}
                  >
                    {console.log(hourKey)}
                    <Tab eventKey="sub_hourly" title="Sub Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="hourly" title="Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="three_hourly" title="Three Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="six_hourly" title="Six Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="hourly_12" title="Twelve Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="hourly_24" title="Twenty four Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                  </Tabs>
                </Tab>
                <Tab eventKey="rcp8" title="RCP 8.5">
                  <Tabs
                    id="uncont"
                    defaultActiveKey="sub_hourly"
                    className="mb-3"
                    activeKey={hourKey}
                    onSelect={(k) => setHourKey(k)}
                  >
                    <Tab eventKey="sub_hourly" title="Sub Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="hourly" title="Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="three_hourly" title="Three Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="six_hourly" title="Six Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="hourly_12" title="Twelve Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                    <Tab eventKey="hourly_24" title="Twenty four Hourly">
                      <GraphComponent
                        gridData={gridData[bounds.gridNo - 1]}
                        coordinates={gridLatLong[bounds.gridNo]}
                        period={hourKey}
                        rcp={key}
                      />
                    </Tab>
                  </Tabs>
                </Tab>
              </Tabs>
            ) : null}
            {timeline === "history" ? (
              <Tabs
                defaultActiveKey="sub_hourly"
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={hourKey}
                onSelect={(k) => setHourKey(k)}
              >
                <Tab eventKey="sub_hourly" title="Sub Hourly">
                  <GraphComponent
                    gridData={pastGridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    period={hourKey}
                  />
                </Tab>
                <Tab eventKey="hourly" title="Hourly">
                  <GraphComponent
                    gridData={pastGridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    period={hourKey}
                  />
                </Tab>
                <Tab eventKey="three_hourly" title="Three Hourly">
                  <GraphComponent
                    gridData={pastGridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    period={hourKey}
                  />
                </Tab>
                <Tab eventKey="six_hourly" title="Six Hourly">
                  <GraphComponent
                    gridData={pastGridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    period={hourKey}
                    rcp={key}
                  />
                </Tab>
                <Tab eventKey="hourly_12" title="Twelve Hourly">
                  <GraphComponent
                    gridData={pastGridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    period={hourKey}
                    rcp={key}
                  />
                </Tab>
                <Tab eventKey="hourly_24" title="Twenty four Hourly">
                  <GraphComponent
                    gridData={pastGridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    period={hourKey}
                    rcp={key}
                  />
                </Tab>
              </Tabs>
            ) : null}
          </Tab>
          <Tab eventKey="data-table" title="Tables">
            {timeline === "future" ? (
              <Tabs
                defaultActiveKey="rcp4"
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="rcp4" title="RCP 4.5">
                  <Tables
                    gridData={gridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    rcp={key}
                  />
                </Tab>
                <Tab eventKey="rcp8" title="RCP 8.5">
                  <Tables
                    gridData={gridData[bounds.gridNo - 1]}
                    coordinates={gridLatLong[bounds.gridNo]}
                    rcp={key}
                  />
                </Tab>
              </Tabs>
            ) : null}
            {timeline === "history" ? (
              <Tables
                gridData={pastGridData[bounds.gridNo - 1]}
                coordinates={gridLatLong[bounds.gridNo]}
              />
            ) : null}
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default CellModal;
