import React, { useState } from 'react'
import AuthBase from './AuthBase'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../firebase-config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import Alert from 'react-bootstrap/Alert'

const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

 
    const login = async (e)=>{
        e.preventDefault();
        try{
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/info");
            setShowAlert(true);
        }
        catch(error){
            if(error.code === "auth/invalid-email"){
                setEmail('');
                setErrorMessage("Invalid Email");
            }
            else if(error.code === "auth/wrong-password"){
                setPassword('');
                setErrorMessage("The Username or the password entered is invalid");

            }
            else if(error.code === "auth/user-not-found"){
                setEmail('');
                setPassword('');
                setErrorMessage("Username doesn't exisits");
            }
            setShowAlert(true);

        }
    }
  

  return (
    <AuthBase>
      <>
        <div>
          <div className='mb-5'>
            <label className='form-label' htmlFor='email'>
              Email address
            </label>
            <input
              type='email'
              name='email'
              id='email'
              className='form-control form-control-muted'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className='mb-5'>
            <label className='form-label' htmlFor='password'>
              Password
            </label>
            <input
              type='password'
              name='password'
              id='password'
              className='form-control form-control-muted'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>
          <div className='mb-5'>
            <div className='form-check'>
              <input
                type='checkbox'
                name='keepmeloggedin'
                id='keepmeloggedin'
              />
              <label className='form-check-label' htmlFor='check_example'>
                Keep me logged in
              </label>
            </div>
          </div>
          <div>
            <button
              type='submit'
              className='btn btn-primary w-full'
              onClick={login}>
              Login
            </button>
          </div>
        </div>
        <div className='my-6'>
          <small>Don't have an account?</small>
          <Link to='/register' className='text-warning text-sm font-semibold'>
            Register
          </Link>
        </div>
        {showAlert ? (
          <Alert
            variant='danger'
            onClose={() => setShowAlert(false)}
            dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        ) : (
          <div></div>
        )}
      </>
    </AuthBase>
  )
}

export default LoginPage;
