import {
  MapContainer,
  TileLayer,
  Rectangle,
  useMap,
  LayersControl,
} from 'react-leaflet'
import { useState, useEffect } from 'react'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import gridCenters from '../long_lat.json'
import CellModal from './CellModal'
import { FullscreenControl } from 'react-leaflet-fullscreen'
import 'react-leaflet-fullscreen/dist/styles.css'

const icon = L.icon({ iconUrl: '/images/marker-icon.png' })

const SetBoundsRectangles = ({ grid, bounds, setBounds,timeline }) => {
  //bounds, setBounds,

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    setBounds({
      data: [
        [-2.05, 44.9],
        [39.5, 119.24],
      ],
      gridNo: 6969,
    })
  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        handleClose()
        setBounds({
          data: [
            [-2.05, 44.9],
            [39.5, 119.24],
          ],
          gridNo: 6969,
        })
        map.flyToBounds([
          [-2.05, 44.9],
          [39.5, 119.24],
        ])
      }
    })
  }, [])

  useEffect(() => {
    if (bounds.gridNo !== 6969 && !show) {
      map.flyToBounds(bounds.data)
      handleShow()
    }
  }, [bounds])

  const map = useMap()

  const boundFit = (currBound) => ({
    click() {
      setBounds(currBound)
      if (bounds.gridNo !== 6969 && !show) {
        map.flyToBounds(bounds.data)
        handleShow()
      }
    },
  })
  return (
    <>
      {grid.map((currBound, index) => {
        return (
          <Rectangle
            key={index}
            bounds={currBound.data}
            pathOptions={{
              fillColor: currBound.data === bounds ? 'blue' : '#daf7e2',
              color: 'black',
              weight: 0.1,
              opacity: 0.5,
              fillOpacity: 0.6,
            }}
            eventHandlers={boundFit(currBound)}
          />
        )
      })}
      <CellModal show={show} handleClose={handleClose} bounds={bounds} />
    </>
  )
}

const DisplayMap = ({ grid, bounds, setBounds,timeline }) => {

  return (
    <>
      <div
        id='map'
        className='rounded p-3 border border-secondary shadow-5'
        style={{ height: '80vh', width: '100%' }}>
        <MapContainer
          center={[gridCenters[2300].Lat, gridCenters[2600].Long]}
          zoom={5}
          scrollWheelZoom={true}
          maxBounds={bounds.data}
          maxBoundsViscosity={1}
          minZoom={5}>
          <LayersControl>
            <LayersControl.BaseLayer checked name='OpenStreetMap'>
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name='Topo View'>
              <TileLayer
                url='https://c.tile.opentopomap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name='Image View'>
              <TileLayer
                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
                maxZoom={20}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name='Grey View'>
              <TileLayer
                url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.png'
                maxZoom={20}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name='Watercolor'>
              <TileLayer
                url='http://a.tile.stamen.com/watercolor/{z}/{x}/{y}.png'
                maxZoom={20}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <SetBoundsRectangles
            grid={grid}
            bounds={bounds}
            setBounds={setBounds}
            timeline={timeline}
          />
          <FullscreenControl forceSeparateButton={true} />
        </MapContainer>
      </div>
    </>
  )
}

export default DisplayMap
