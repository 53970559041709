import React from 'react';
import poster from "../assets/poster.jpeg"

const Outreach = () => {
  return (
    <div className="container py-2" style={{ }}>
      <img alt='poster' style={{width: '100%'}} src={poster}/>
    </div>
  );
};

export default Outreach;
