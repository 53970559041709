import DisplayMap from "../components/DisplayMap";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import gridCenters from "../long_lat.json";
import { ReactOsmGeocoding } from "@paraboly/react-osm-geocoding";
import "@paraboly/react-osm-geocoding/dist/index.css";
import { Alert } from "react-bootstrap";

const gridGenerate = () => {
  const grid = [];
  const step = 0.25 / 2;
  for (let i = 0; i < gridCenters.length; i++) {
    grid.push({
      data: [
        [gridCenters[i].Lat - step, gridCenters[i].Long - step],
        [gridCenters[i].Lat + step, gridCenters[i].Long + step],
      ],
      gridNo: gridCenters[i].gridNo,
    });
  }
  return grid;
};
const grid = gridGenerate();

function Home() {
  const [user, loading] = useAuthState(auth);
  const [alert, showAlert] = useState(false);
  const [bounds, setBounds] = useState({
    data: [
      [-3.05, 54.9],
      [43.5, 105.24],
    ],
    gridNo: 6969,
  });
  const lonRef = useRef();
  const latRef = useRef();

  const onSubmitHandler = (lon, lat) => {
    console.log(grid[0].data);
    for (let i = 0; i < grid.length; i++) {
      if (
        grid[i].data[0][0] < lat &&
        grid[i].data[1][0] > lat &&
        grid[i].data[0][1] < lon &&
        grid[i].data[1][1] > lon
      ) {
        setBounds(grid[i]);
        break;
      } else if (i === grid.length - 1) {
        showAlert(true);
      }
    }
  };

  const modes = ["DEFAULT", "SEARCHBYPLACE", "SEARCHBYLATLONG"];

  const [mode, setMode] = useState(modes[0]);

  const handleModeChange = (name) => {
    setMode(name);
  };

  const getDisplayName = (val) => {
    if (val === modes[0]) return "Default";
    else if (val === modes[1]) return "Search by place";
    else if (val === modes[2]) return "Search by Latitude and Longitude";
  };
  const { timeline } = useParams();

  return (
    <div>
      {!user ? (
        <Navigate to="/info" />
      ) : (
        <>
          <div className="mb-3">
            <div className="bg-surface-secondary mb-3">
              <div className="py-7 container max-w-screen-xl">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 col-lg-8 text-center">
                    {timeline === "future" ? (
                      <h2 className="ls-tight font-bolder mb-5">
                        IDF's for future data
                      </h2>
                    ) : (
                      <h2 className="ls-tight font-bolder mb-5">
                        IDF's for Historical data
                      </h2>
                    )}

                    <p className="lead mb-10"></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container py-5">
              <div className="d-flex mb-3">
                {modes.map((e) => (
                  <div
                    className={
                      "cursor-pointer px-6 py-2 " +
                      (e === mode
                        ? "border-bottom-2 border-primary text-primary fw-bold"
                        : "text-dark")
                    }
                    key={e}
                    onClick={() => handleModeChange(e)}
                  >
                    {getDisplayName(e)}
                  </div>
                ))}
              </div>
              {mode === modes[1] ? (
                <div className="container mt-5">
                  <SearchComponent onSubmitHandler={onSubmitHandler} />
                </div>
              ) : (
                <></>
              )}
              {mode === modes[2] ? (
                <div className="container">
                  <LatLongForm
                    latRef={latRef}
                    lonRef={lonRef}
                    onSubmitHandler={onSubmitHandler}
                  />
                </div>
              ) : (
                <></>
              )}
              {alert && (
                <Alert
                  variant="danger"
                  onClose={() => showAlert(false)}
                  dismissible
                >
                  <Alert.Heading>
                    We do not provide data for that coordinate yet
                  </Alert.Heading>
                  <p>Please enter a valid coordinate inside india</p>
                </Alert>
              )}
            </div>
            <div className="container mb-10">
              <div className="container">
                <DisplayMap
                  grid={grid}
                  bounds={bounds}
                  setBounds={setBounds}
                  timeline={timeline}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Home;

const SearchComponent = ({ onSubmitHandler }) => (
  <ReactOsmGeocoding
    callback={(data) => {
      let lat =
        (parseFloat(data.boundingbox[0]) + parseFloat(data.boundingbox[1])) / 2;
      let lon =
        (parseFloat(data.boundingbox[2]) + parseFloat(data.boundingbox[3])) / 2;
      onSubmitHandler(lon, lat);
    }}
    countrycodes="in"
    acceptLanguage="en"
    debounce={300}
  />
);

const LatLongForm = ({ latRef, lonRef, onSubmitHandler }) => (
  <form
    action="/action_page.php"
    onSubmit={(e) => {
      e.preventDefault();
      const lon = parseFloat(lonRef.current.value);
      const lat = parseFloat(latRef.current.value);
      onSubmitHandler(lon, lat);
    }}
  >
    <div className="d-flex align-items-end justify-content-center">
      <div className="flex-grow-1 me-3">
        <label className="form-label" htmlFor="lat">
          Latitude
        </label>
        <input
          className="form-control"
          type="text"
          id="lat"
          name="firstname"
          placeholder="lat"
          ref={latRef}
        />
      </div>
      <div className="flex-grow-1 me-3">
        <label className="form-label" htmlFor="lon">
          Longitude
        </label>
        <input
          className="form-control"
          type="text"
          id="lon"
          name="lastname"
          placeholder="lon"
          ref={lonRef}
        />
      </div>
      <button className="btn btn-outline-success" type="submit">
        Find
      </button>
    </div>
  </form>
);
