import React from 'react';

const AuthBase = (props) => {
  return (
    <div className="px-5 py-5 p-lg-0 bg-surface-secondary">
      <div className="d-flex justify-content-center">
        <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
          <div className="row">
            <div className="col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0">
              <div className="mt-10 mt-lg-5 mb-6 d-flex align-items-center d-lg-block">
                <h1 className="ls-tight font-bolder h2">Nice to see you! 👋</h1>
              </div>
              <div>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthBase;
