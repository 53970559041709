import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC7Vu-lFUfYEOssLYiffl2YmAC5GqTdVKM",
  authDomain: "idf-tool-9b281.firebaseapp.com",
  projectId: "idf-tool-9b281",
  storageBucket: "idf-tool-9b281.appspot.com",
  messagingSenderId: "952916767891",
  appId: "1:952916767891:web:8ecd3fe52ca625c12e0966",
  measurementId: "G-4WEJGXT4V3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore();
export { auth, analytics, db };
