import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import image from "./../assets/graph.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  Filler
);

const getPeriod = (period) => {
  if (period === "sub_hourly") return "sub hourly";
  if (period === "hourly") return "hourly";
  if (period === "three_hourly") return "three hourly";
  if (period === "six_hourly") return "six hourly";
  if (period === "hourly_12") return "twelve hourly";
  if (period === "hourly_24") return "twenty four hourly";
};

const GraphComponent = ({
  gridData: gridDataList,
  coordinates,
  rcp,
  period,
}) => {
  console.log(gridDataList);
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        ticks: {
          stepSize: 20,
        },
        title: {
          text: "Rainfall Depth (mm)",
          display: true,
          font: {
            size: 15,
            weight: 600,
          },
        },
      },
      // doesnt work for x axis
      x: {
        type: "logarithmic",
        scaleOverride: true,
        min: 0,
        suggestedMin: 0,
        ticks: {
          beginAtZero: true,
        },
        title: {
          text: "Return Period (year)",
          display: true,
          font: {
            size: 15,
            weight: 600,
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          filter: function (label) {
            if (
              label.text === `Mean ${getPeriod(period)} design rainfall` ||
              label.text === "Standard deviation"
            )
              return true;
          },

          boxWidth: 100,
          font: {
            size: 13,
          },
        },
      },
      title: {
        display: true,
        text: `Latitude ${coordinates.lat} | Longitude ${
          coordinates.long
        } (${getPeriod(period)} rainfall)`,
        font: {
          size: 15,
        },
      },
    },
  };

  const labels = Array.from(Array(130).keys()).map((e) => e * 5);
  const { timeline } = useParams();
  // let timeline = "future";
  const [currData, setCurrData] = useState(null);

  useEffect(() => {
    let tempData, colors;
    if (timeline === "future") {
      if (rcp === "rcp4") {
        tempData = gridDataList.rcp45[period];
      } else {
        tempData = gridDataList.rcp85[period];
      }
    } else {
      tempData = gridDataList[period];
    }
    if (period === "sub_hourly") {
      colors = {
        bg: "rgba(255, 99, 132, 0.2)",
        border: "rgba(255, 99, 132, 1)",
      };
    } else if (period === "hourly") {
      colors = {
        bg: "rgba(53, 162, 235, 0.2)",
        border: "rgb(53, 162, 235)",
      };
    } else {
      colors = {
        bg: "rgba(15, 238, 102, 0.2)",
        border: "rgb(15, 238, 102)",
      };
    }

    let data = {
      labels,
      datasets: [
        {
          label: `Mean ${getPeriod(period)} design rainfall`,
          data: labels.map((e) => {
            if (e === 5) {
              return Math.round(tempData.mean.qq5);
            } else if (e === 25) {
              return Math.round(tempData.mean.qq25);
            } else if (e === 100) {
              return Math.round(tempData.mean.qq100);
            } else if (e === 500) {
              return Math.round(tempData.mean.qq500);
            }
            return null;
          }),
          borderWidth: 2,
          fill: "+2",
          borderColor: colors.border,
          backgroundColor: colors.bg,
          pointRadius: 0,
          spanGaps: true,
          pointStyle: "line",
        },
        {
          label: `Standard deviation`,
          data: labels.map((e) => {
            if (e === 5) {
              return Math.round(
                parseFloat(tempData.mean.qq5) + parseFloat(tempData.stddev.qq5)
              );
            } else if (e === 25) {
              return Math.round(
                parseFloat(tempData.mean.qq25) +
                  parseFloat(tempData.stddev.qq25)
              );
            } else if (e === 100) {
              return Math.round(
                parseFloat(tempData.mean.qq100) +
                  parseFloat(tempData.stddev.qq100)
              );
            } else if (e === 500) {
              return Math.round(
                parseFloat(tempData.mean.qq500) +
                  parseFloat(tempData.stddev.qq500)
              );
            }
            return null;
          }),
          borderWidth: 1,
          fill: "stack",
          pointStyle: "rect",
          pointRadius: 0,
          borderDash: [5, 10],
          borderColor: colors.border,
          backgroundColor: colors.bg,
          spanGaps: true,
        },
        {
          label: `hello`,
          data: labels.map((e) => {
            if (e === 5) {
              return Math.round(tempData.mean.qq5 - tempData.stddev.qq5);
            } else if (e === 25) {
              return Math.round(tempData.mean.qq25 - tempData.stddev.qq25);
            } else if (e === 100) {
              return Math.round(tempData.mean.qq100 - tempData.stddev.qq100);
            } else if (e === 500) {
              return Math.round(tempData.mean.qq500 - tempData.stddev.qq500);
            }
            return null;
          }),
          borderWidth: 1,
          borderDash: [5, 10],
          borderColor: colors.border,
          backgroundColor: colors.bg,
          fill: false,
          pointRadius: 0,
          pointStyle: "rectdash",
          spanGaps: true,
        },
      ],
    };
    setCurrData(data);
  }, [period, timeline]);

  return (
    <div className="container">
      {/* <h3 style={{ textAlign: 'center' }}>IDF Graph</h3> */}
      {currData ? <Line options={options} data={currData} /> : null}
    </div>
  );
};

export default GraphComponent;
