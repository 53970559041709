import "./App.css";
import "./main.scss";
import { Routes, Route,Navigate } from "react-router";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoginPage from "./components/Login";
import RegisterPage from "./components/Register";
import LandingPage from "./Pages/Landing";
import Team from "./Pages/Team";
import Home from "./Pages/Home";
import Help from "./Pages/Help";
import ModelDescription from "./Pages/ModelDescription";
import Outreach from "./Pages/Outreach";
import DataPage from "./Pages/DataPage";
import Publications from "./Pages/Publications";
import { analytics } from "./firebase-config";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import SponsorComponent from "./components/sponsor";
import Disclaimer from "./components/Disclaimer";

function App() {
  useEffect(() => {
    logEvent(analytics, "user_visited");
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/info" replace />} />
        <Route path="/info" exact element={<LandingPage />} />
        <Route path="team" exact element={<Team />} />
        <Route path="help" exact element={<Help />} />
        <Route path="model_details" exact element={<ModelDescription />} />
        <Route path="outreach" exact element={<Outreach />} />
        <Route path="data_details" exact element={<DataPage />} />
        <Route path="publications" exact element={<Publications />} />
        <Route path="register" exact element={<RegisterPage />} />
        <Route path="login" exact element={<LoginPage />} />
        <Route path="/:timeline" element={<Home />} />
      </Routes>
      <SponsorComponent />
      <Disclaimer />
      <Footer />
    </div>
  );
}

const Temp = () => <div>Page yet to come!</div>;

export default App;
